import { Box } from '@mui/material';
import { MdPreview } from 'md-editor-rt';
import 'md-editor-rt/lib/preview.css';
import { AuthInfo } from '../hocComponents/Backend';
import { withAuth } from '../hocComponents/withAuth';

type Props = {
    authInfo: AuthInfo;
};

function generate_company_markdown(company_name: string): string {
    return `
# ${company_name} Information

Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam euismod, nunc id ultrices tincidunt, nisl nulla tincidunt nunc, ac tincidunt nunc metus id nunc. Sed id semper nunc. Sed auctor, nunc a tincidunt tincidunt, nunc nunc tincidunt nunc, id tincidunt nunc nunc a nunc. Sed auctor, nunc a tincidunt tincidunt, nunc nunc tincidunt nunc, id tincidunt nunc nunc a nunc.

## Company Details

- Name: ${company_name}
- Location: New York
- Industry: Technology

## About Us

Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam euismod, nunc id ultrices tincidunt, nisl nulla tincidunt nunc, ac tincidunt nunc metus id nunc. Sed id semper nunc. Sed auctor, nunc a tincidunt tincidunt, nunc nunc tincidunt nunc, id tincidunt nunc nunc a nunc. Sed auctor, nunc a tincidunt tincidunt, nunc nunc tincidunt nunc, id tincidunt nunc nunc a nunc.

## Contact Information

- Email: info@company.com
- Phone: 123-456-7890
    `;
}

const CompanyPage: React.FC<Props> = (props) => {
    return (
        <Box>
            <MdPreview editorId="api_settings_md" modelValue={generate_company_markdown("XPTO Inc.")} />
        </Box>
    );
};

export default withAuth(CompanyPage);