
import { createBrowserRouter } from "react-router-dom";
import APISettingsPage from "../pages/APISettingsPage";
import CompanyPage from "../pages/CompanyPage";
import DSProfileImportPage from "../pages/DSProfileImportPage";
import ErrorPage from "../pages/ErrorPage";
import FullReportGeneratePage from "../pages/FullReportGeneratePage";
import FullReportHistoryPage from "../pages/FullReportHistoryPage";
import FullReportLastPage from "../pages/FullReportLastPage";
import HomePage from "../pages/HomePage";
import LogoutPage from "../pages/LogoutPage";
import RiskMatrixCompensatoryPage from "../pages/RiskMatrixCompensatoryPage";
import RiskMatrixGdprPage from "../pages/RiskMatrixGdprPage";
import RiskMatrixSatPage from "../pages/RiskMatrixSatPage";
import RiskMatrixSodPage from "../pages/RiskMatrixSodPage";
import TempPage from "../pages/TempPage";
import UserProfilePage from "../pages/UserProfilePage";
 
export const router = createBrowserRouter([
  { path: "/", element: <HomePage />, errorElement: <ErrorPage />, },
  { path: "/logout", element: <LogoutPage />, },
  { path: "/user-profile", element: <UserProfilePage />, },
  { path: "/company", element: <CompanyPage />, },
  { path: "/api-settings", element: <APISettingsPage />, },
  { path: "/full-report-generate", element: <FullReportGeneratePage />, },
  { path: "/full-report-last", element: <FullReportLastPage />, },  
  { path: "/full-report-history", element: <FullReportHistoryPage />, },
  //ds-dashboard-general
  { path: "/ds-dashboard-general", element: <TempPage />, },
  { path: "/ds-profile-import", element: <DSProfileImportPage />, },
  { path: "/ds-view-roles", element: <TempPage />, },
  { path: "/ds-view-users", element: <TempPage />, },
  { path: "/ds-view-objects", element: <TempPage />, },
  { path: "/ds-rule-import", element: <TempPage />, },
  { path: "/risk-matrix-sod", element: <RiskMatrixSodPage />, },
  { path: "/risk-matrix-sat", element: <RiskMatrixSatPage />, },
  { path: "/risk-matrix-gdpr", element: <RiskMatrixGdprPage />, },
  { path: "/risk-matrix-compensatory", element: <RiskMatrixCompensatoryPage />, },

]);
