import { Alert, Box, Button, CircularProgress, Container, FormControl, FormControlLabel, FormHelperText, FormLabel, Paper, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { AuthInfo } from '../hocComponents/Backend';
import { withAuth } from '../hocComponents/withAuth';

type Props = {
    authInfo: AuthInfo;
};


const sleep = (ms: number) => {
    return new Promise(resolve => setTimeout(resolve, ms));
};

// Interface for the file upload function
const uploadFiles = async (files: { sodFile: File, satFile: File, gdprFile: File, compensatoryControlFile: File }) => {
    // Placeholder for the actual upload logic
    // This function should return a promise
    await sleep(10000); // Sleep for 10 seconds
    // Continue with the upload logic
};

// Interface for the SAP fetch function
const fetchFromSap = async (config: { server: string, username: string, password: string }) => {
    // Placeholder for the actual fetch logic
    // This function should return a promise
    await sleep(10000); // Sleep for 10 seconds
    // Continue with the fetch logic
};

const DSProfileImportPage: React.FC<Props> = (props) => {
    const [importType, setImportType] = useState<string>('');
    const [sodFile, setSodFile] = useState<File | null>(null);
    const [satFile, setSatFile] = useState<File | null>(null);
    const [gdprFile, setGdprFile] = useState<File | null>(null);
    const [compensatoryControlFile, setCompensatoryControlFile] = useState<File | null>(null);
    const [sapServer, setSapServer] = useState<string>('');
    const [sapUsername, setSapUsername] = useState<string>('');
    const [sapPassword, setSapPassword] = useState<string>('');
    const [error, setError] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [success, setSuccess] = useState<string>('');

    const handleImportTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setImportType(event.target.value);
        setError(''); // Clear error when changing import type
        setSuccess(''); // Clear success message when changing import type
    };

    const handleFileChange = (setter: React.Dispatch<React.SetStateAction<File | null>>) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setter(event.target.files ? event.target.files[0] : null);
    };

    const handleManualImport = async () => {
        if (!sodFile || !satFile || !gdprFile || !compensatoryControlFile) {
            setError('All CSV files must be provided.');
        } else {
            setError('');
            setLoading(true);
            try {
                await uploadFiles({ sodFile, satFile, gdprFile, compensatoryControlFile });
                setLoading(false);
                setSuccess('Import was successful and data is already available in the "Rules" menu.');
            } catch (e) {
                setLoading(false);
                setError(`Upload has failed: ${e}`);
            }
        }
    };

    const handleSapImport = async () => {
        if (!sapServer || !sapUsername || !sapPassword) {
            setError('All SAP configuration fields must be provided.');
        } else {
            setError('');
            setLoading(true);
            try {
                await fetchFromSap({ server: sapServer, username: sapUsername, password: sapPassword });
                setLoading(false);
                setSuccess('Import was successful and data is already available in the "Rules" menu.');
            } catch (e) {
                setLoading(false);
                setError(`Fetch has failed: ${e}`);
            }
        }
    };

    return (
        <Box p={2} display="flex" textAlign="center" justifyContent="center">
            <Paper elevation={3} >
                <Box p={2} >
                    <Container>
                        <Typography variant="h4" align="center" gutterBottom>
                            Profiles data import
                        </Typography>
                        <RadioGroup row value={importType} onChange={handleImportTypeChange}>
                            <FormControlLabel value="Manual" control={<Radio />} label="Manual" />
                            <FormControlLabel value="SAP" control={<Radio />} label="SAP" />
                        </RadioGroup>
                        {importType === 'Manual' && (
                            <Box>
                                <Typography variant="h6">Upload CSV Files</Typography>
                                <FormControl fullWidth margin="normal">
                                    <FormLabel>Roles / Transactions File</FormLabel>
                                    <TextField
                                        type="file"
                                        inputProps={{ accept: '.csv' }}
                                        onChange={handleFileChange(setSodFile)}
                                    />
                                    <FormHelperText>Upload the Roles / Transactions CSV file.</FormHelperText>
                                </FormControl>
                                <FormControl fullWidth margin="normal">
                                    <FormLabel>Profiles / Transactions File</FormLabel>
                                    <TextField
                                        type="file"
                                        inputProps={{ accept: '.csv' }}
                                        onChange={handleFileChange(setSatFile)}
                                    />
                                    <FormHelperText>Upload the Profiles / Transactions CSV file.</FormHelperText>
                                </FormControl>
                                <FormControl fullWidth margin="normal">
                                    <FormLabel>Risks / Roles File</FormLabel>
                                    <TextField
                                        type="file"
                                        inputProps={{ accept: '.csv' }}
                                        onChange={handleFileChange(setGdprFile)}
                                    />
                                    <FormHelperText>Upload the Risks / Roles file.</FormHelperText>
                                </FormControl>
                                <FormControl fullWidth margin="normal">
                                    <FormLabel>Users / Profiles</FormLabel>
                                    <TextField
                                        type="file"
                                        inputProps={{ accept: '.csv' }}
                                        onChange={handleFileChange(setCompensatoryControlFile)}
                                    />
                                    <FormHelperText>Upload the Users / Profiles CSV file.</FormHelperText>
                                </FormControl>
                                <Button variant="contained" color="primary" onClick={handleManualImport} disabled={loading}>
                                    Import
                                </Button>
                                {loading && (
                                    <Box display="flex" alignItems="center" mt={2}>
                                        <CircularProgress size={24} />
                                        <Typography variant="body2" ml={2}>Uploading...</Typography>
                                    </Box>
                                )}
                                {error && <Alert severity="error">{error}</Alert>}
                                {success && <Alert severity="success">{success}</Alert>}
                            </Box>
                        )}
                        {importType === 'SAP' && (
                            <form onSubmit={handleSapImport}>
                                <Box>
                                    <Typography variant="h6">SAP Configuration</Typography>
                                    <FormControl fullWidth margin="normal">
                                        <FormLabel>SAP Server</FormLabel>
                                        <TextField
                                            value={sapServer}
                                            onChange={(e) => setSapServer(e.target.value)}
                                        />
                                        <FormHelperText>Enter the SAP server address.</FormHelperText>
                                    </FormControl>
                                    <FormControl fullWidth margin="normal">
                                        <FormLabel>Username</FormLabel>
                                        <TextField
                                            value={sapUsername}
                                            onChange={(e) => setSapUsername(e.target.value)}
                                            autoComplete="username"
                                        />
                                        <FormHelperText>Enter the SAP username.</FormHelperText>
                                    </FormControl>
                                    <FormControl fullWidth margin="normal">
                                        <FormLabel>Password</FormLabel>
                                        <TextField
                                            type="password"
                                            value={sapPassword}
                                            onChange={(e) => setSapPassword(e.target.value)}
                                            autoComplete="current-password"
                                        />
                                        <FormHelperText>Enter the SAP password.</FormHelperText>
                                    </FormControl>
                                    <Button type="submit" variant="contained" color="primary" disabled={loading}>
                                        Import
                                    </Button>
                                    {loading && (
                                        <Box display="flex" alignItems="center" mt={2}>
                                            <CircularProgress size={24} />
                                            <Typography variant="body2" ml={2}>Fetching...</Typography>
                                        </Box>
                                    )}
                                    {error && <Alert severity="error">{error}</Alert>}
                                    {success && <Alert severity="success">{success}</Alert>}
                                </Box>
                            </form>
                        )}
                    </Container>
                </Box>
            </Paper>
        </Box>
    );
};

export default withAuth(DSProfileImportPage);