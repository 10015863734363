import { ApiTwoTone, AssessmentTwoTone, AssignmentIndTwoTone, CallToActionTwoTone, ExpandLess, ExpandMore, HomeTwoTone, ImportExportTwoTone, NoteAddTwoTone, PolicyTwoTone, SplitscreenTwoTone, SummarizeTwoTone, WorkHistoryTwoTone } from "@mui/icons-material";
import {
  Box,
  Collapse,
  Divider,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Toolbar
} from "@mui/material";
import { Fragment, useState } from "react";
import { Drawer } from "../styles/AppSideBarStyles";

const topSideBarItems = [
  { title: "Home", icon: <HomeTwoTone />, href: "/" },
  { title: "=" },
  { title: "Risk Reports and Queries" },
  {
    title: "Full report", icon: <AssessmentTwoTone />, subItems: [
      { title: "Generate", icon: <NoteAddTwoTone />, href: "/full-report-generate" },
      { title: "Last report", icon: <SummarizeTwoTone />, href: "/full-report-last" },
      { title: "History", icon: <WorkHistoryTwoTone />, href: "/full-report-history" },      
    ]
  }, 
  {
    title: "Report Integration", icon: <AssessmentTwoTone />, subItems: [
      { title: "API Settings", icon: <ApiTwoTone />, href: "/api-settings" },
    ]
  },


  { title: "=" },
  { title: "Data sources" },
  {
    title: "Dashboard", icon: <NoteAddTwoTone />, subItems: [
      { title: "General", icon: <ImportExportTwoTone />, href: "/ds-dashboard-general" },
    ]
  },
  {
    title: "Profiles", icon: <NoteAddTwoTone />, subItems: [
      { title: "Set up import", icon: <ImportExportTwoTone />, href: "/ds-profile-import" },
      { title: "Roles", icon: <SplitscreenTwoTone />, href: "/ds-view-roles" },
      { title: "Users", icon: <AssignmentIndTwoTone />, href: "/ds-view-users" },
      { title: "Objects", icon: <AssignmentIndTwoTone />, href: "/ds-view-objects" },
    ]
  },

  {
    title: "Rules", icon: <NoteAddTwoTone />, subItems: [
      { title: "Set up import", icon: <ImportExportTwoTone />, href: "/ds-rule-import" },
      { title: "SOD", icon: <SplitscreenTwoTone />, href: "/risk-matrix-sod" },
      { title: "SAT", icon: <AssignmentIndTwoTone />, href: "/risk-matrix-sat" },
      { title: "GDPR", icon: <PolicyTwoTone />, href: "/risk-matrix-gdpr" },
      { title: "Compensatory control", icon: <CallToActionTwoTone />, href: "/risk-matrix-compensatory" },
    ]
  },
];

interface AppSideBarProps {
  open: boolean;
}

const renderSideBarSingleItem = (open: boolean, item: any, key: string, ident: number, expansion: boolean, closed: boolean, toggleItemClosed: { (item: any): void; (item: any): void; }) => (
  <ListItem key={key} disablePadding sx={{ pl: 1 * ident }}>
    <ListItemButton sx={{ minHeight: 28, p: 0 }} component={Link} href={item.href} onClick={(expansion) ? () => toggleItemClosed(key) : () => { }}>
      <ListItemIcon sx={{ minWidth: 28 }}>{item.icon}</ListItemIcon>
      {open && <ListItemText primary={item.title} /> }
      {expansion && (closed ? <ExpandMore /> : <ExpandLess />)}
    </ListItemButton>
  </ListItem>
);


const renderSideBarItems = (open: boolean, listId: string, items: any[], ident: number, isItemClosed: { [x: string]: boolean; }, toggleItemClosed: { (item: any): void; (item: any): void; }) => {

  return <List key={`${listId}`}>
    {items.map((item, index) => {
      const key = `${listId}_${item.title}_${index}_${ident}`;
      if (item.title === "-") {
        return <Divider sx={{ mt: 0 }} key={`${key}_divider`} />;
      } else if (item.title === "=") {
        return <Divider sx={{ mt: 4 }} key={`${key}_divider`} />;
      } else if (item.subItems !== undefined) {
        return <Fragment key={key}>
          {renderSideBarSingleItem(open, item, key, ident, true, isItemClosed[key], toggleItemClosed)}
          <Collapse in={!isItemClosed[key]} timeout="auto" unmountOnExit>
            {renderSideBarItems(open, `${key}_list`, item.subItems, ident + 1, isItemClosed, toggleItemClosed)}
          </Collapse>
        </Fragment>
      } else if (item.href === undefined) {
        return <ListSubheader key={`${key}_label`} component="div" id={`${key}_subheader`} style={{ paddingLeft: 4 + 4 * ident }}>{open && item.title}</ListSubheader>;
      }
      return (
        renderSideBarSingleItem(open, item, key, ident, false, false, toggleItemClosed)
      );
    }
    )}
  </List>
}

export function AppSideBar({ open }: AppSideBarProps) {
  const [isItemClosed, setItemClosed] = useState<{ [key: string]: boolean }>({});
  const toggleItemClosed = (key: string) => {
    setItemClosed({ ...isItemClosed, [key]: !isItemClosed[key] });
    console.log(isItemClosed);
  }
  return (
    <Drawer variant="permanent" open={open}>
      <Toolbar />
      <Box key="topItems" flexGrow={1} >
        {renderSideBarItems(open, "0", topSideBarItems, 0, isItemClosed, toggleItemClosed)}
      </Box>
    </Drawer>
  );
}
