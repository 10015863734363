
import { useState } from 'react';

import { Box, useMediaQuery, useTheme } from '@mui/material';
import { Amplify } from "aws-amplify";
import { RouterProvider } from "react-router-dom";
import awsConfig from '../aws-exports';
import { router } from '../router/router';
import { DrawerHeader } from '../styles/AppSideBarStyles';
import { AppSideBar } from './AppSideBar';
import { AppBarTop } from './AppTopBar';
Amplify.configure(awsConfig);


function App() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const [sidebarOpen, setSidebarOpen] = useState(isDesktop);
  const handleToggleSidebar = () => setSidebarOpen(!sidebarOpen);

  return (
    <>
      <Box display={'flex'} alignContent={'flex-start'} alignItems={'flex-start'} textAlign={'left'} alignSelf={'left'} height= "90vh">
        <AppBarTop handleToggleSidebar={handleToggleSidebar} />

        <AppSideBar open={sidebarOpen} />
        <Box component="main" style={{ width: "100%", height: "100%" }}>
          <DrawerHeader /> 
          <RouterProvider router={router} />
        </Box>        
      </Box>
    </>
  );
}

export default App;
