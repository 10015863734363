import MenuIcon from '@mui/icons-material/Menu';
import { Breadcrumbs, Link, Typography } from '@mui/material';
import MuiAppBar, { AppBarProps } from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import { styled } from '@mui/material/styles';
import theme from '../styles/theme';
import AuthenticatedUser from './AuthenticatedUser';

interface AppTopBarProps {
  handleToggleSidebar: () => void;
}

const AppBar = styled(MuiAppBar, {})<AppBarProps>(
  ({ theme, }) => ({
    zIndex: theme.zIndex.drawer + 1,
  })
);

const pathPartDashToCamel = (pathPart: string) => {
  return pathPart.split('-').map((part, index) => {
    const camelPart = part.charAt(0).toUpperCase() + part.slice(1)
    if (index === 0) {
      return camelPart;
    }
    return ' ' + camelPart;
  }).join('');
}

export function AppBarTop({ handleToggleSidebar }: AppTopBarProps) {

  const path = window.location.pathname.split('/');

  return (
    <Box sx={{ flexGrow: 1, }}>
      <AppBar position="fixed"  >
        <Toolbar  >
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ mr: 2, }}
            onClick={handleToggleSidebar}
          >
            <MenuIcon />
          </IconButton>

          <Box sx={{ flexShrink: 1 }} />

          <Link href={"/"}>
            <img src="/logo192_transpbg.png" alt="logo" style={{ width: "40px", height: "40px", }} />
          </Link>
          <Box sx={{ flexGrow: 1, pl: 3, }} >
            <Breadcrumbs aria-label="breadcrumb" style={{ color: theme.palette.secondary.main }}>
              <Link underline="hover" color="inherit" href="/">
                Identia
              </Link>
              {path.slice(1, -1).map((path_part, index) => (
                <Link
                  underline="hover"
                  color="inherit"
                  href="/material-ui/getting-started/installation/"
                >
                  {pathPartDashToCamel(path_part)}
                </Link>
              ))}
              {path[path.length - 1] && <Typography sx={{ color: "inherit" }}>{pathPartDashToCamel(path[path.length - 1])}</Typography>}
            </Breadcrumbs>
          </Box>
          <Box sx={{ flexShrink: 1 }} />

          <AuthenticatedUser />
        </Toolbar>
      </AppBar>
    </Box>
  );
}

