import { Box, Paper } from '@mui/material';
import { MdPreview } from 'md-editor-rt';
import 'md-editor-rt/lib/preview.css';
import React, { useEffect, useState } from 'react';
import { AuthInfo } from '../hocComponents/Backend';
import { withAuth } from '../hocComponents/withAuth';

type Props = {
    authInfo: AuthInfo;
};
const APISettingsPage: React.FC<Props> = (props) => {
    const [text, setText] = useState('loading...');

    useEffect(() => {
        fetch('/docs/api_settings.md')
            .then(response => response.text())
            .then(data => setText(data))
            .catch(error => {
                console.error(error);
                setText('Error loading file');
            });
    }, []);
    return (
        <Box p={2} display="flex" justifyContent="center">
            <Paper elevation={3}  >
                <Box p={2} >
                    <MdPreview editorId="api_settings_md" modelValue={text}  language='en-US'/>
                </Box>
            </Paper>
        </Box>
    );
};

export default withAuth(APISettingsPage);