import { Box, Paper } from '@mui/material';
import { DataGrid, GridPaginationMeta, useGridApiRef } from '@mui/x-data-grid';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { AuthInfo } from '../hocComponents/Backend';
import { withAuth } from '../hocComponents/withAuth';

type Props = {
    authInfo: AuthInfo;
};

interface RowData {
    id: number;
    risk: string;
    impact: string;
    likelihood: string;
    riskLevel: string;
}

// Mock function to simulate fetching data
const fetchRiskMatrixData = async (page: number, pageSize: number, filters: any) => {
    const rows = [];
    const startIndex = (page) * pageSize + 1;
    for (let i = startIndex; i < startIndex + pageSize; i++) {
        const risk = `Risk ${page} ${i - startIndex + 1}`;
        const impact = `Impact ${page} ${i - startIndex + 1}`;
        const likelihood = `Likelihood ${page} ${i - startIndex + 1}`;
        const riskLevel = `Risk Level ${page} ${i - startIndex + 1}`;
        rows.push({ id: i, risk, impact, likelihood, riskLevel });
    }
    const json_data = {
        rows,
        total: pageSize * 10,
    };
    return {
        json: () => json_data,
    };
};
const useQuery = (paginationModel: { page: number; pageSize: number }, filters: any) => {
    const [isLoading, setLoading] = useState(false);
    const [rows, setRows] = useState<RowData[]>([]);
    const [hasNextPage, setHasNextPage] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            console.log('fetching data', paginationModel, filters);
            setLoading(true);
            const response = await fetchRiskMatrixData(paginationModel.page, paginationModel.pageSize, filters);
            const data = await response.json();
            setRows(data.rows);
            setHasNextPage(data.total > (paginationModel.page + 1) * paginationModel.pageSize);
            setLoading(false);
        };
        fetchData();
    }, [paginationModel, filters]);

    return { isLoading, rows, pageInfo: { hasNextPage } };
};
const FullReportLastPage: React.FC<Props> = (props) => {
    const apiRef = useGridApiRef();
    const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 10 });
    const [filters, setFilters] = useState<any>({});

    const { isLoading, rows, pageInfo: { hasNextPage } } = useQuery(paginationModel, filters);

    const paginationMetaRef = useRef<GridPaginationMeta>({});
    const paginationMeta = useMemo(() => {
        if (hasNextPage !== undefined && paginationMetaRef.current?.hasNextPage !== hasNextPage) {
            paginationMetaRef.current = { hasNextPage };
        }
        return paginationMetaRef.current;
    }, [hasNextPage]);

    const handleFilterChange = (newFilters: any) => {
        setFilters(newFilters);
    };
    return (
        <Box p={2} height="100%" display="flex" textAlign="center" justifyContent="center">
            <Paper elevation={3} >
                <Box height="100%" display="flex" flexDirection="column">
                    <Box textAlign={'center'} p={2}>
                        <h1>Last Full Report</h1> 
                        <p>Generated at {new Date().toLocaleString()}</p>
                    </Box>
                    <Box p={2} flexGrow={1}>
                        <DataGrid
                            apiRef={apiRef}
                            rows={rows}
                            columns={[
                                { field: 'id', headerName: 'ID', width: 90 },
                                { field: 'risk', headerName: 'Risk', width: 150 },
                                { field: 'impact', headerName: 'Impact', width: 150 },
                                { field: 'likelihood', headerName: 'Likelihood', width: 150 },
                                { field: 'riskLevel', headerName: 'Risk Level', width: 150 },
                            ]}
                            initialState={{ pagination: { rowCount: -1 } }}
                            rowCount={2000}
                            paginationMeta={paginationMeta}
                            loading={isLoading}
                            autoPageSize
                            paginationModel={paginationModel}
                            paginationMode="server"
                            onPaginationModelChange={setPaginationModel}
                            // filterMode="server" TODO
                            onFilterModelChange={handleFilterChange}
                            disableColumnSorting
                        />
                    </Box>
                </Box>
            </Paper>
        </Box >
    );
};

export default withAuth(FullReportLastPage);