import { Box, Paper } from '@mui/material';
import { DataGrid, GridPaginationMeta, useGridApiRef } from '@mui/x-data-grid';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { AuthInfo } from '../hocComponents/Backend';
import { withAuth } from '../hocComponents/withAuth';

type Props = {
    authInfo: AuthInfo;
};

interface RowData {
    id: number;
    generated_at: string;
    generated_by: string;
    file_name: string;
    num_risks: number; 
}

// Mock function to simulate fetching data
const fetchRiskMatrixData = async (page: number, pageSize: number, filters: any) => {
    const rows = [];
    const startIndex = (page) * pageSize + 1;
    for (let i = startIndex; i < startIndex + pageSize; i++) {
        const generated_at = new Date(new Date().setDate(new Date().getDate() - i)).toLocaleString();
        const generated_by = `User_${page}_${i - startIndex + 1}`;
        const file_name = `full_risk_report_${new Date().toISOString().replace(/[-:.]/g, '')}.csv`;
        const num_risks = i;
        rows.push({ id: i, generated_at, generated_by, file_name, num_risks });
    }
    const json_data = {
        rows,
        total: pageSize * 10,
    };
    return {
        json: () => json_data,
    };
};
const useQuery = (paginationModel: { page: number; pageSize: number }, filters: any) => {
    const [isLoading, setLoading] = useState(false);
    const [rows, setRows] = useState<RowData[]>([]);
    const [hasNextPage, setHasNextPage] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            console.log('fetching data', paginationModel, filters);
            setLoading(true);
            const response = await fetchRiskMatrixData(paginationModel.page, paginationModel.pageSize, filters);
            const data = await response.json();
            setRows(data.rows);
            setHasNextPage(data.total > (paginationModel.page + 1) * paginationModel.pageSize);
            setLoading(false);
        };
        fetchData();
    }, [paginationModel, filters]);

    return { isLoading, rows, pageInfo: { hasNextPage } };
};
const FullReportHistoryPage: React.FC<Props> = (props) => {
    const apiRef = useGridApiRef();
    const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 10 });
    const [filters, setFilters] = useState<any>({});

    const { isLoading, rows, pageInfo: { hasNextPage } } = useQuery(paginationModel, filters);

    const paginationMetaRef = useRef<GridPaginationMeta>({});
    const paginationMeta = useMemo(() => {
        if (hasNextPage !== undefined && paginationMetaRef.current?.hasNextPage !== hasNextPage) {
            paginationMetaRef.current = { hasNextPage };
        }
        return paginationMetaRef.current;
    }, [hasNextPage]);

    const handleFilterChange = (newFilters: any) => {
        setFilters(newFilters);
    };
    return (
        <Box p={2} height="100%" display="flex" textAlign="center" justifyContent="center">
            <Paper elevation={3} >
                <Box height="100%" display="flex" flexDirection="column">
                    <Box textAlign={'center'} p={2}>
                        <h1>Full report history</h1>                         
                    </Box>
                    <Box p={2} flexGrow={1}>
                        <DataGrid
                            apiRef={apiRef}
                            rows={rows}
                            columns={[
                                { field: 'id', headerName: 'ID', width: 90 },
                                { field: 'generated_at', headerName: 'Generated At', width: 150 },
                                { field: 'generated_by', headerName: 'Generated By', width: 150 },
                                { 
                                    field: 'file_name', 
                                    headerName: 'File Name', 
                                    width: 350,
                                    renderCell: (params) => (
                                        <a href="/sample_historic_report.csv" download>
                                            {params.value}
                                        </a>
                                    ),
                                },
                                { field: 'num_risks', headerName: 'Number of Risks', width: 150 },
                            ]}
                            initialState={{ pagination: { rowCount: -1 } }}
                            rowCount={2000}
                            paginationMeta={paginationMeta}
                            loading={isLoading}
                            autoPageSize
                            paginationModel={paginationModel}
                            paginationMode="server"
                            onPaginationModelChange={setPaginationModel}
                            // filterMode="server" TODO
                            onFilterModelChange={handleFilterChange}
                            disableColumnSorting
                        />
                    </Box>
                </Box>
            </Paper>
        </Box >
    );
};

export default withAuth(FullReportHistoryPage);