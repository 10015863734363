import { Alert, AlertTitle, Box, CircularProgress, Paper } from '@mui/material';
import React, { useState } from 'react';
import { AuthInfo } from '../hocComponents/Backend';
import { withAuth } from '../hocComponents/withAuth';
// eslint-disable-next-line react-hooks/rules-of-hooks

type Props = {
    authInfo: AuthInfo;
};

async function generateReport(authInfo: AuthInfo) {
    // const response = await fetch('/api/generate-report', {
    //     method: 'POST',
    //     headers: {
    //         'Content-Type': 'application/json',
    //     },
    //     body: JSON.stringify({ authInfo }),
    // });

    // if (!response.ok) {
    //     // Handle error case
    //     console.error('Failed to generate report');
    //     throw new Error('Failed to generate report');
    // }

    // Simulate a long running operation
    const sleep = (ms: number) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    };
    await sleep(3000);
    // end of simulation
}

const FullReportGeneratePage: React.FC<Props> = (props) => {
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const handleGenerateReport = async () => {
        setLoading(true);
        try {
            await generateReport(props.authInfo);
            
            setSuccess(true);
        } catch (error) {
            console.error('Error:', error);
            setError(`Failed to generate report ${error}`);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box p={2} display="flex" textAlign="center" justifyContent="center">
            <Paper elevation={3} style={{maxWidth: 400}}>
                <Box p={2} >
                    <h1>Generate full report</h1>
                    <p>
                        This page allows you to generate a full report of risks associated to profiles currently imported into the system.
                    </p>
                    <p>
                        The report will contain all the information about the risks associated to each profile, including the risk score, the risk level, the risk description, the risk mitigation, and the risk impact.
                    </p>
                    {loading ? (
                        <div>
                            <CircularProgress />
                            <p>Generating full report...</p>
                        </div>
                    ) : success ? (
                        <Box >
                            <Alert severity="success">
                                <AlertTitle>Success</AlertTitle>
                                Successfully generated the new report
                            </Alert>
                        </Box>
                    ) : (
                        <button onClick={handleGenerateReport}>Generate report</button>
                    )}

                    {error && (
                        <div>
                            <p>Error: {error}</p>
                        </div>
                    )}
                </Box>
            </Paper>
        </Box>
    );
};

export default withAuth(FullReportGeneratePage);