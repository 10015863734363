import React from 'react';
import { AuthInfo } from '../hocComponents/Backend';
import { withAuth } from '../hocComponents/withAuth';

type Props = {
    authInfo: AuthInfo;
};

const TempPage: React.FC<Props> = (props) => {

    return (
        <div style={{ textAlign: "center" }}>
            TempPage TODO This page is under construction
        </div>
    );
};

export default withAuth(TempPage);