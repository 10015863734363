import { ResourcesConfig } from '@aws-amplify/core';

// Read valid domains from environment variable and parse into an array
const validDomains = (process.env.REACT_APP_VALID_DOMAINS || '').split(',');

// Get the current domain
const currentDomain = window.location.hostname;
const currentOrigin = window.location.origin; 

// Validate the current domain
if (!validDomains.includes(currentDomain)) {
    throw new Error(`Invalid domain: ${currentDomain}`);
}

const aws_config: ResourcesConfig = {
    Auth: {
        Cognito: {
            userPoolId: process.env.REACT_APP_USER_POOL_ID || '',
            userPoolClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID || '',
            signUpVerificationMethod: 'code',
            loginWith: {
                oauth: {
                    domain: process.env.REACT_APP_OAUTH_DOMAIN || '',
                    scopes: ['email', 'openid'],
                    redirectSignIn: [`${currentOrigin}/user-profile`],
                    redirectSignOut: [`${currentOrigin}/logout`],
                    responseType: 'code',
                },
            },
        },
    },
};

export default aws_config;