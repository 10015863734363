import { AccountBox, Business, Logout, MoreVert, Person } from '@mui/icons-material';
import { Avatar, Box, Button, Divider, IconButton, Link, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from '@mui/material';


import { useState } from 'react';
import { getGravatarUrl } from '../common/gravatar';
import { signInAction, signOutAction, useAuthInfoContext } from '../hocComponents/AuthProvider';

export default function AuthenticatedUser() {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState<null | HTMLElement>(null);

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    const authInfo = useAuthInfoContext();


    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };
    const menuId = 'primary-search-account-menu';
    const commonMenu = [ // both mobile and desktop 
        (
            <MenuItem key="1" component={Link} href="/user-profile">
                <ListItemIcon>
                    <AccountBox />
                </ListItemIcon>
                <ListItemText primary="Profile" />
            </MenuItem>
        ),
        (
            <Divider key="2" />
        ),
        (
            <MenuItem key="3" component={Link} href="/company">
                <ListItemIcon>
                    <Business />
                </ListItemIcon>
                <ListItemText primary="Company" />
            </MenuItem>
        ), 
        (
            <Divider key="9" />
        ),
        (
            <MenuItem key="100" onClick={signOutAction}>
                <ListItemIcon>
                    <Logout />
                </ListItemIcon>
                <ListItemText primary="Logout" />
            </MenuItem>
        )
    ];

    const renderMenu = (
        <Menu
            id={menuId}
            anchorEl={anchorEl}
            open={isMenuOpen}
            onClose={handleMenuClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
            {commonMenu}
        </Menu>
    );

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            {authInfo ? (
                <div>
                    <MenuItem>
                        <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                            {authInfo.providerInfo?.email}
                        </Typography>
                    </MenuItem> 
                    <Divider />
                    {commonMenu}
                </div>) : (
                <MenuItem onClick={signInAction}>
                    <ListItemIcon>
                        <Person />
                    </ListItemIcon>
                    <ListItemText primary="Sign In" />
                </MenuItem>
            )}
        </Menu>
    );

    return (
        <div>
            {authInfo ? (
                <div>
                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}  > 
                        <Button onClick={handleMenuOpen} sx={{ p: 0 }}>
                            <Avatar src={getGravatarUrl(authInfo.providerInfo?.email || "")} sx={{ width: 32, height: 32 }} />
                        </Button>

                    </Box>

                </div>
            ) : (
                <div>
                    <Box sx={{ display: { xs: 'none', md: 'flex' }, ml: 3 }}  >
                        <Button variant="contained" startIcon={<Person />}
                            onClick={signInAction}
                        >
                            Sign In
                        </Button>
                    </Box>
                </div>
            )}

            <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                <IconButton
                    size="large"
                    aria-label="show more"
                    aria-controls={mobileMenuId}
                    aria-haspopup="true"
                    onClick={handleMobileMenuOpen}
                    color="inherit"
                >
                    <MoreVert />
                </IconButton>
            </Box>
            {renderMobileMenu}
            {renderMenu}
        </div>
    );
}